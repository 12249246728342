import "./index.css"

const CustomButton = ({ title, disabled, loading }) => {
  return (
    <button
      disabled={loading || disabled}
      type="submit"
      className="w-full text-white text-[16px] font-[400] rounded-[1px] focus:ring-4 focus:outline-none focus:ring-blue-300 px-5 py-2.5 text-center hover:bg-blue-400 focus:ring-blue-800 button_color"
    >
      <div className="flex">
        <div className="w-full">{title}</div>
        {loading && <div class="loader"></div>}
      </div>
    </button>
  )
}

export default CustomButton
